.underline_ufoz0lg{border-top:1px solid #EBEBEB;}
.blueOfferBox_bpjijjm{background:#3869FF;border-radius:4px;padding:16px 24px;margin-top:24px;}.blueOfferBox_bpjijjm .MuiTypography-root{color:#FFFFFF;}.blueOfferBox_bpjijjm .MuiTypography-button{font-weight:700;-webkit-text-decoration:underline;text-decoration:underline;}.blueOfferBox_bpjijjm .MuiButton-text{padding:8px 16px 8px 0px;}
.contractForm_c1wcdv33{width:100%;}.contractForm_c1wcdv33 input:-webkit-autofill,.contractForm_c1wcdv33 input:-webkit-autofill:focus{-webkit-transition:background-color 600000s 0s,color 600000s 0s;transition:background-color 600000s 0s,color 600000s 0s;}.contractForm_c1wcdv33 input[data-autocompleted]{background-color:transparent !important;}.contractForm_c1wcdv33 form .MuiFormLabel-root{font-size:1.125rem;font-weight:700;}.contractForm_c1wcdv33 form .MuiTextField-root{margin-top:16px;}
.inputLabel_i77eefm.MuiTypography-root{margin:24px 0px;font-size:1.125rem;font-weight:700;color:#2E0F4F;}
.errorLabel_e1ob7s3{color:red !important;}
.selectClass_sxxibzv.MuiInput-root{background-color:#F8F8F8;border-radius:50px;overflow:hidden;width:100%;}.selectClass_sxxibzv.MuiInput-root .MuiSelect-select.MuiSelect-select{padding-left:24px;padding-top:12px;padding-bottom:14px;font-size:0.9375rem;font-weight:400;}
.activeSelect_a75jmxy.MuiInput-root{background:#F8F8F8;color:#2E0F4F;}.activeSelect_a75jmxy.MuiInput-root .MuiSelect-select.MuiSelect-select{color:#2E0F4F;}
.errorInput_ec92710{border:1px solid red !important;}
.icon_ii98fcg{padding-right:8px;}.icon_ii98fcg.MuiSelect-icon{color:#2E0F4F;}
.activeIcon_al4n3er.MuiSelect-icon{color:#2E0F4F;}
.option_o1f1rmr{border:none;outline:none;}.option_o1f1rmr.MuiListItem-root{padding-top:0px;padding-bottom:0px;font:normal normal normal 15px/21px Poppins;}@media (min-width:960px){.option_o1f1rmr.MuiListItem-root{padding-top:8px;padding-bottom:8px;}}.option_o1f1rmr.MuiMenuItem-root:hover,.option_o1f1rmr.MuiListItem-root.Mui-selected,.option_o1f1rmr.MuiListItem-root.Mui-selected:hover{color:white;background-color:#2E0F4F;}
.submitClasses_s1skps2v{margin:8px 0px 16px;}
.loading_lf16yhj{position:absolute;right:36.24px;}
