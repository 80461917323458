.section_s1n4rloo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.wrapper_w1d3go1a{max-width:800px;padding:0px 16px;}.wrapper_w1d3go1a img{padding:0px !important;object-fit:contain;}@media (min-width:960px){.wrapper_w1d3go1a{padding:0px 0px;max-width:980px;}}
.fullWidth_f1xnfuby{width:100%;}
.paddings_pn3ayp7{padding:40px 0px 40px;}
.greyGradientBackground_g1tq178u{background-image:linear-gradient(180deg,white 0%,#F8F8F8 100%);}
.primaryGradientBackground_pgdrchp{background-image:linear-gradient(180deg,#380450 0%,#30071A 100%);}
.whiteText_w1g5ok2f{color:white;}.whiteText_w1g5ok2f .MuiTypography-root{color:white;}.whiteText_w1g5ok2f [class*="itemTitle"],.whiteText_w1g5ok2f [class*="itemText"] p{color:white !important;}
.titleWrapper_th8sb78{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;text-align:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:#524658;}.titleWrapper_th8sb78 .MuiTypography-body1{margin-top:32px;font-weight:400;}@media (min-width:960px){.titleWrapper_th8sb78 .MuiTypography-body1{max-width:580px;}}
.title_tbesrw0{color:#2E0F4F;max-width:760px;}@media (min-width:960px){.title_tbesrw0{font-size:2.188rem !important;}}
.largeTitle_lo0rjfw{max-width:660px;}@media (min-width:960px){.largeTitle_lo0rjfw{font-size:2.813rem !important;}}
.itemContainer_i1ahptfn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.itemContainer_i1ahptfn .nextImageWrapper{margin-bottom:16px;}
.mediaWrapper_m19ej9wa{margin-bottom:16px;}@media (max-width:599.95px){.mediaWrapper_m19ej9wa{max-width:128px;}}
.itemContainerGrid_itietfb.MuiGrid-container{display:grid;gap:32px;grid-template-columns:repeat(3,1fr);}.itemContainerGrid_itietfb.MuiGrid-container [class*=itemContainer]{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;max-width:100%;padding:0;width:100%;}.itemContainerGrid_itietfb.MuiGrid-container [class*=itemContainer] [class*=iconTextWrapper]:last-child{margin-bottom:0;}@media (max-width:959.95px){.itemContainerGrid_itietfb.MuiGrid-container{gap:16px;}}@media (max-width:599.95px){.itemContainerGrid_itietfb.MuiGrid-container{gap:48px;grid-template-columns:repeat(1,1fr);}}
.itemContainerNoPadding_i1iqolmp{padding-top:0;}
.centerText_c32zoky{text-align:center;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.centerText_c32zoky a{max-width:100%;margin:auto;}
@media (min-width:960px){.iconTextWrapper_iygft0m{text-align:center;}}
.bottomArrowBtn_b1tntg2k{min-width:272px !important;font-size:1.125rem !important;}.bottomArrowBtn_b1tntg2k:hover{background-color:transparent !important;}.bottomArrowBtn_b1tntg2k .MuiButton-label:hover{-webkit-text-decoration:underline;text-decoration:underline;}.bottomArrowBtn_b1tntg2k svg{margin-left:10px;margin-top:3px;}
.itemTitle_i5uvvb1{padding-bottom:8px;}.itemTitle_i5uvvb1.MuiTypography-root{color:#2E0F4F;font-weight:700;}
@media (min-width:960px){.itemText_iie1d1s{text-align:center;}}.itemText_iie1d1s p{margin-top:8px;margin:0;font-weight:400;line-height:27px;color:#524658;}.itemText_iie1d1s a{color:#17AB33;font-weight:700;}
.buttonDiv_b9aii6y{margin-top:56px;text-align:center;}
.hiddenExpandButton_hlwqy29{margin-bottom:56px;}
.slider_s5m1gmj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:56px;text-align:center;}@media (max-width:1279.95px){.slider_s5m1gmj{margin-top:32px;}}.slider_s5m1gmj img{margin:auto;max-width:70vw;}@media (max-width:1279.95px){.slider_s5m1gmj img{margin-bottom:16px;width:100%;}}.slider_s5m1gmj .MuiTypography-root{padding:0 24px;}.slider_s5m1gmj .slick-list{margin:auto;min-width:100% !important;overflow:visible !important;}.slider_s5m1gmj .slick-arrow{position:absolute;z-index:10;top:calc(50% - 30px);margin:0 16px;width:48px;height:48px;}.slider_s5m1gmj .slick-arrow:before{border:solid #2E0F4F;border-width:0 2px 2px 0;display:inline-block;font-size:0.45rem;opacity:1 !important;}.slider_s5m1gmj .slick-prev:before{position:relative;left:2px;-webkit-transform:rotate(135deg);-ms-transform:rotate(135deg);transform:rotate(135deg);}.slider_s5m1gmj .slick-next:before{position:relative;right:2px;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);}
