.itemWrapper_ijug8s8{position:relative;background-size:cover;background-position:center;border-radius:20px;text-align:center;}.itemWrapper_ijug8s8 .MuiTypography-root{z-index:1;position:relative;}
.overlayWrapper_oowpzve:after{content:"";position:absolute;top:0;left:0;height:100%;width:100%;background-color:#2E0F4F;opacity:.6;border-radius:20px;}
.close_ca7vlwf.MuiButtonBase-root{cursor:pointer;position:absolute;top:8px;right:8px;}.close_ca7vlwf.MuiButtonBase-root svg{color:#2E0F4F;}
.boxesWrapper_b1x31u2y{color:#2E0F4F;position:relative;}
.slideContainer_sf7e2u5{position:absolute;top:7px;bottom:8px;z-index:100;background:white;box-shadow:0px 3px 40px #0000001F;border-radius:20px;width:100%;text-align:left;}@media (min-width:960px){.slideContainer_sf7e2u5{min-height:100%;top:0;bottom:auto;}}
.slideText_s1av7w35{padding:56px 40px 16px;}@media (min-width:960px){.slideText_s1av7w35{padding:32px 40px 0px;}}.slideText_s1av7w35 .MuiTypography-body1{font-weight:400;}.slideText_s1av7w35 .MuiTypography-body1 > div > p{color:#524658;}.slideText_s1av7w35 .MuiTypography-h5{color:#2E0F4F;font-weight:500;}
.slideLinkWrapper_sg56so0 .MuiLink-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0px 40px 56px;}@media (min-width:960px){.slideLinkWrapper_sg56so0 .MuiLink-root{margin:0px 40px 32px;}}.slideLinkWrapper_sg56so0 .MuiTypography-body1{font-weight:400;}.slideLinkWrapper_sg56so0 .MuiSvgIcon-root{padding-top:6px;width:16px;height:16px;}
.overlapText_o14e2foo{background:white;box-shadow:0px 3px 40px #0000001F;border-radius:20px;padding:16px 24px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);text-align:center;}@media (min-width:960px){.overlapText_o14e2foo{padding:24px 56px;}}.overlapText_o14e2foo .MuiTypography-root{font-weight:500;}
.primaryTitle_p6sm6u1{color:#2E0F4F;}
.whiteTitle_w1i3xq1n{color:white;}
