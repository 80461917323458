.importantInfo_itklf84{color:#FF3D3D;margin-top:8px;}.importantInfo_itklf84 .MuiTypography-body1{font-size:14px;}.importantInfo_itklf84 .MuiTypography-body1.italicfont{font-style:italic;}.importantInfo_itklf84 .MuiTypography-body2{font-size:16px;padding:0 !important;}
.expansionPanelDetails_egibgc1{margin-bottom:16px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:4px;}
.expansionPanelTight_ex3m2ig,.expansionPanelTight_ex3m2ig.MuiExpansionPanelSummary-root,.expansionPanelTight_ex3m2ig.MuiExpansionPanelSummary-root.Mui-expanded{min-height:0px;}.expansionPanelTight_ex3m2ig + .MuiCollapse-container .MuiCollapse-wrapperInner{padding-bottom:16px;}
.productLabel_p17r8kpx.MuiTypography-body2{line-height:1.2;font-weight:600;}
.basketTitle_by3cy0w{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}.basketTitle_by3cy0w.MuiTypography-h4{font-size:21px !important;}
.stickyDesktopContainer_se39cao{position:-webkit-sticky;position:sticky;top:16px;}.stickyDesktopContainer_se39cao .MuiButton-root{min-width:100%;}@media (min-width:960px){.stickyDesktopContainer_se39cao{max-width:325px;}}.stickyDesktopContainer_se39cao button[type=submit]{width:100%;}
.stickyMobileContainer_sq3c8k2{position:fixed;top:0;right:0;left:0;z-index:999;}.stickyMobileContainer_sq3c8k2 .MuiExpansionPanelSummary-root{padding:0 16px;}.stickyMobileContainer_sq3c8k2 .MuiExpansionPanelSummary-content,.stickyMobileContainer_sq3c8k2 .MuiExpansionPanelSummary-content.Mui-expanded,.stickyMobileContainer_sq3c8k2 .MuiExpansionPanelSummary-root.Mui-expanded{margin:0;}.stickyMobileContainer_sq3c8k2 .MuiExpansionPanelDetails-root{background-color:#FFFFFF;margin:0 16px;padding:0;border-radius:0 0 20px 20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.stickyMobileContainer_sq3c8k2 .MuiExpansionPanelDetails-root > div{width:auto;}.stickyMobileContainer_sq3c8k2 .MuiPaper-root{box-shadow:none;background-color:transparent;min-width:100%;}
@media (min-width:960px){.container_c1qrp207{margin-top:16px;}.container_c1qrp207 .MuiTypography-h4{font-size:1.875rem;}}
.backdropContainer_b1gimdu5{background-color:rgba(0,0,0,0.5);min-height:100%;}
.monthlyHeader_mmp3qv0{min-width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-radius:20px;color:white;min-height:64px;}.monthlyHeader_mmp3qv0 .MuiSvgIcon-root{width:35px;height:25px;padding-right:16px;}.monthlyHeader_mmp3qv0 .MuiTypography-root{padding:0 8px;}.monthlyHeader_mmp3qv0 .MuiTypography-root span{padding:0 6px;}@media (min-width:960px){.monthlyHeader_mmp3qv0{border-radius:20px 20px 0 0;}}
.openMonthlyHeader_o1cef6st{border-radius:20px 20px 0 0;}.openMonthlyHeader_o1cef6st .MuiSvgIcon-root{height:32px;position:relative;right:-5px;}
.orderSummary_o1o2gn2u{border:1px solid #EBEBEB;color:#2E0F4F;padding:24px 16px 0px 16px;width:100%;}.orderSummary_o1o2gn2u:not(.hasTimeslot){border-radius:0 0 20px 20px;}.orderSummary_o1o2gn2u::-webkit-scrollbar{width:5px;}.orderSummary_o1o2gn2u::-webkit-scrollbar-thumb{background-color:#EBEBEB;border-radius:40px;}@media (min-width:960px){.orderSummary_o1o2gn2u{width:auto;}}@media (max-width:959.95px){.orderSummary_o1o2gn2u{max-height:80vh;overflow-y:scroll;}}.orderSummary_o1o2gn2u a{cursor:pointer;}.orderSummary_o1o2gn2u [class*=flagsContainer]{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}
.noMaxHeight_nrx85ib{max-height:100%;}
.strikeThrough_s1r85746{-webkit-text-decoration-line:line-through;text-decoration-line:line-through;-webkit-text-decoration-thickness:1.5px;text-decoration-thickness:1.5px;}
.priceStrikeThrough_p1feq5zk{line-height:1.2;-webkit-text-decoration:line-through;text-decoration:line-through;font-weight:500;color:#FF3D3D;}
.actualPrice_a1prakxl{font-weight:700;}
.basketExpansion_b1589ej .MuiExpansionPanelSummary-content,.basketExpansion_b1589ej .MuiExpansionPanelSummary-content.Mui-expanded,.basketExpansion_b1589ej .MuiExpansionPanelSummary-root.Mui-expanded{margin:0;}.basketExpansion_b1589ej .MuiExpansionPanelSummary-root{-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0;}.basketExpansion_b1589ej .MuiPaper-root{box-shadow:none;border-radius:0px !important;position:relative;min-width:100%;}.basketExpansion_b1589ej .MuiButtonBase-root .MuiExpansionPanelSummary-content{padding:16px 0px;}.basketExpansion_b1589ej .MuiGrid-grid-xs-3{text-align:right;}.basketExpansion_b1589ej .Mui-expanded > .MuiExpansionPanelSummary-expandIcon,.basketExpansion_b1589ej .MuiExpansionPanelSummary-expandIcon{color:#2E0F4F;top:8px;padding:0px 0px 0px 8px;}.basketExpansion_b1589ej .Mui-expanded > .MuiExpansionPanelSummary-expandIcon svg,.basketExpansion_b1589ej .MuiExpansionPanelSummary-expandIcon svg{font-size:2.188rem;}.basketExpansion_b1589ej .Mui-expanded > .MuiExpansionPanelSummary-expandIcon{padding:0px 8px 0px 0px;}.basketExpansion_b1589ej .MuiExpansionPanelDetails-root{padding:0;}
.priceBreakdownExpansion_p1ew5rkm .MuiExpansionPanelSummary-root{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;min-height:auto;}.priceBreakdownExpansion_p1ew5rkm .MuiExpansionPanelSummary-root.Mui-expanded{min-height:auto;}.priceBreakdownExpansion_p1ew5rkm .MuiExpansionPanelDetails-root{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-bottom:15px;}.priceBreakdownExpansion_p1ew5rkm .MuiExpansionPanelDetails-root .MuiTypography-root{padding-top:8px;}.priceBreakdownExpansion_p1ew5rkm .MuiExpansionPanelSummary-content{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;padding:0;}.priceBreakdownExpansion_p1ew5rkm .MuiExpansionPanelSummary-expandIcon{bottom:0 !important;}.priceBreakdownExpansion_p1ew5rkm .MuiIconButton-edgeEnd{margin-right:0;}.priceBreakdownExpansion_p1ew5rkm .MuiTypography-root{font-size:0.75rem;-webkit-text-decoration:underline;text-decoration:underline;font-weight:400 !important;}.priceBreakdownExpansion_p1ew5rkm .MuiSvgIcon-root{height:25px;width:25px;}
.breakdownText_b9roges{font-size:0.9375rem !important;line-height:1.2 !important;-webkit-text-decoration:none !important;text-decoration:none !important;}
.desktopDirectDebit_d1l1485d{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:325px;color:#707070;}.desktopDirectDebit_d1l1485d .MuiGrid-root:first-child{-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;}.desktopDirectDebit_d1l1485d .MuiTypography-root{font-weight:400;text-align:center;}@media (min-width:1280px){.desktopDirectDebit_d1l1485d .MuiTypography-root{text-align:left;padding-left:24px;}}.desktopDirectDebit_d1l1485d .MuiLink-root{color:#707070;padding:0;}
.installationContainer_ikbt08f{color:white;}.installationContainer_ikbt08f .MuiTypography-root{font-weight:400;}.installationContainer_ikbt08f div{border-radius:0 0 20px 20px;padding:8px 0;text-align:center;}
.selectedSlotContainer_sazqp36{background-color:#2E0F4F;}.selectedSlotContainer_sazqp36 div > .MuiTypography-root:nth-child(2){text-transform:uppercase;}
.promoCode_phqcvtr{border-top:1px solid #EBEBEB;}.promoCode_phqcvtr .Mui-expanded > .MuiExpansionPanelSummary-expandIcon,.promoCode_phqcvtr .MuiExpansionPanelSummary-expandIcon{bottom:-5px;}.promoCode_phqcvtr .MuiButton-label > .MuiTypography-body1{font-weight:400;}
