.container_cy4372w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:100%;}@media (min-width:960px){.container_cy4372w{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}
.alignImageLeft_as6z2p [class*="heroContainer"]{-webkit-order:2;-ms-flex-order:2;order:2;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}@media (min-width:960px){.alignImageLeft_as6z2p [class*="heroContainer"]{-webkit-order:2;-ms-flex-order:2;order:2;}}.alignImageLeft_as6z2p [class*="imagesContainer"]{-webkit-order:1;-ms-flex-order:1;order:1;}@media (min-width:960px){.alignImageLeft_as6z2p [class*="imagesContainer"]{-webkit-order:1;-ms-flex-order:1;order:1;}}.alignImageLeft_as6z2p [class*="textImage"]{left:10px;right:initial;}@media (min-width:600px){.alignImageLeft_as6z2p [class*="textImage"]{left:30px;right:initial;}}
.heroTextContainer_h1ty72w2.MuiGrid-item{margin:0 auto;left:initial;padding-top:20px;}@media (min-width:960px){.heroTextContainer_h1ty72w2.MuiGrid-item{padding:24px 16px 24px 16px;}}
.heroBundlesContainer_hlchrjl{position:relative;}@media only screen and (min-width:960px){.heroBundlesContainer_hlchrjl{left:32px;}}@media only screen and (min-width:1270px){.heroBundlesContainer_hlchrjl{left:auto;}}
.heroContainer_humu79o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-order:2;-ms-flex-order:2;order:2;padding:8px 16px 40px;background-color:#F8F8F8;color:#2E0F4F;position:relative;}@media (min-width:960px){.heroContainer_humu79o{-webkit-order:1;-ms-flex-order:1;order:1;-webkit-flex:1;-ms-flex:1;flex:1;margin:0 auto;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;min-height:715px;}}
.heroInfo_h8bc6s2{position:absolute;color:white;}
.heroHeading_h15iat18{padding-top:16px;text-align:center;margin:0 auto;}.heroHeading_h15iat18 span{display:block;}@media (min-width:960px){.heroHeading_h15iat18{padding-top:0;font-size:2.813rem !important;}}
.heroAddress_hgqafcy{padding-top:8px;font-weight:200;}.heroAddress_hgqafcy.MuiTypography-body1{font-weight:200;font-size:0.875rem;padding-right:24px;}@media (min-width:0px){.heroAddress_hgqafcy.MuiTypography-body1{font-size:1.125rem;padding-right:0;}}
.packageName_p1cl81rh.MuiTypography-h2{font-size:1.563rem;font-weight:300 !important;padding-top:24px;margin-bottom:16px;}.packageName_p1cl81rh.MuiTypography-h2 span{font-size:1.563rem;font-weight:700;text-transform:uppercase;}@media (min-width:960px){.packageName_p1cl81rh.MuiTypography-h2{font-size:1.875rem;}.packageName_p1cl81rh.MuiTypography-h2 span{font-size:1.875rem;}}
.productNamePrice_pr921lm{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.priceContainer_p1mhrh9j > .MuiTypography-root + .MuiTypography-root{margin-left:16px;}
.priceContainerBundles_p1pvelxm img{max-width:140px;}@media (min-width:960px){.priceContainerBundles_p1pvelxm img{display:none;}}
.contractContainer_c1xqpmvx{font-family:"Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";padding-left:8px;text-align:center;}@media (min-width:960px){.contractContainer_c1xqpmvx{-webkit-flex:1;-ms-flex:1;flex:1;}}
.prices_pv38iua{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;max-height:80px;}
.oldPrice_o1j5suap{-webkit-text-decoration:line-through;text-decoration:line-through;font-size:1.125rem;font-weight:700;}
.currentPrice_c1ummh13{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;font-size:3.75rem;font-weight:700;color:#C81162;}.currentPrice_c1ummh13 span{font-size:24px;font-weight:400;color:#524658;}
.monthContract_mqh8eeg{color:#524658;}.monthContract_mqh8eeg.MuiTypography-root{font-weight:400;}
.description_dovexoh.MuiTypography-body1{font-weight:400;color:#524658;width:95%;margin-top:24px;margin-bottom:8px;text-align:left;}
.featureList_fccyk59 ul{list-style:none;margin:0;padding:0;}.featureList_fccyk59 li{position:relative;-webkit-break-inside:avoid-column;break-inside:avoid-column;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#524658;padding:4px 0 4px;}.featureList_fccyk59 li .MuiTypography-root{font-weight:400;padding-left:29.6px;font-size:0.9375rem;}.featureList_fccyk59 li .MuiSvgIcon-root{height:15px;width:15px;position:absolute;top:9px;fill:#C81162;}@media (min-width:960px){.featureList_fccyk59 ul{-webkit-column-count:2;column-count:2;padding:0;}}
.devicesImage_d1d39fvk{width:50%;-webkit-filter:brightness(0) invert(1);filter:brightness(0) invert(1);}@media (min-width:0px){.devicesImage_d1d39fvk{margin:8px 0 0 16px;}}
.button_b1j03dnk{width:100%;}.button_b1j03dnk.MuiButton-root{margin-top:32px;}
.buttonContainer_b1fzskdk{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.bottomButtonContainer_btc4xal{padding-top:24px;}@media (max-width:599.95px){.bottomButtonContainer_btc4xal .MuiButton-root{margin:0 auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}.bottomButtonContainer_btc4xal .MuiButton-root .MuiTypography-noWrap{white-space:nowrap !important;}@media (min-width:960px){.bottomButtonContainer_btc4xal{padding-top:32px;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}@media (max-width:959.95px){.bottomButtonContainer_btc4xal{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}}
.tooltip_t4s56yw{pointer-events:auto;}.tooltip_t4s56yw .MuiTooltip-arrow{color:white !important;left:10px !important;}.tooltip_t4s56yw.MuiTooltip-tooltip{max-width:85vw;box-shadow:0px 3px 40px #00000014;background-color:white;color:#2E0F4F;font-weight:400;font-size:0.875rem;position:relative;border-radius:20px;margin:8px 0;}@media (min-width:600px){.tooltip_t4s56yw.MuiTooltip-tooltip{max-width:300px;}}@media (min-width:960px){.tooltip_t4s56yw.MuiTooltip-tooltip{margin:0 16px;}.tooltip_t4s56yw.MuiTooltip-tooltip .MuiTooltip-arrow{left:.2px !important;}}
.tooltipText_t1t34697{color:#2E0F4F;padding:8px 16px;}.tooltipText_t1t34697 .MuiTypography-h5{margin-bottom:8px;}.tooltipText_t1t34697 .MuiSvgIcon-root{color:#2E0F4F;height:22px;width:22px;position:absolute;right:8px;top:5px;cursor:pointer;}.tooltipText_t1t34697 ul{list-style:none;padding:0 !important;margin-top:0;}.tooltipText_t1t34697 ul .MuiTypography-body2{margin-top:4px;font-weight:400;}
.infoIcon_iexto1s{position:relative;top:4px;cursor:pointer;margin-left:8px;}.infoIcon_iexto1s.MuiSvgIcon-root{height:24px;width:24px;font-size:2.188rem;}@media (min-width:960px){.infoIcon_iexto1s{top:0;}}
.broadbandWrapper_b18zosjd{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media (min-width:960px){.broadbandWrapper_b18zosjd{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}
.broadbandPackageInfo_b1nvcxxw img{display:none;}.broadbandPackageInfo_b1nvcxxw .MuiTypography-body2{margin-top:8px;font-weight:400 !important;}.broadbandPackageInfo_b1nvcxxw .MuiGrid-container{width:auto;}@media (min-width:960px){.broadbandPackageInfo_b1nvcxxw{padding-left:16px;}.broadbandPackageInfo_b1nvcxxw .MuiTypography-h4{font-size:1.875rem;}.broadbandPackageInfo_b1nvcxxw img{text-align:left;margin:8px 0px 0px;margin-right:16px;display:block;max-width:140px;}}
.addOnInfo_a1q1k34e{margin-top:24px;}.addOnInfo_a1q1k34e .MuiTypography-body2{font-weight:400;}.addOnInfo_a1q1k34e .MuiSvgIcon-root{font-size:40px;}.addOnInfo_a1q1k34e .MuiGrid-grid-md-2 > .MuiSvgIcon-root:first-child{height:20px;width:20px;margin-right:16px;}@media (min-width:960px){.addOnInfo_a1q1k34e .MuiGrid-grid-md-2{max-width:20%;-webkit-flex-basis:20%;-ms-flex-preferred-size:20%;flex-basis:20%;}.addOnInfo_a1q1k34e .MuiSvgIcon-root:first-child{margin:0px 16px 0px 8px;}}@media (min-width:1280px){.addOnInfo_a1q1k34e .MuiGrid-grid-md-2{max-width:15%;}}
@media (min-width:960px){.addOnText_agi6ty{padding-left:16px;}}
.contractBundlesContainer_cwx0unj{padding-top:16px;margin-bottom:8px;}.contractBundlesContainer_cwx0unj .MuiGrid-container{border-top:1px solid white;border-top:1px solid rgba(255,255,255,.33);}@media (min-width:960px){.contractBundlesContainer_cwx0unj .MuiGrid-container{max-width:70%;}}
.mobileImageContainer_m1kf5qpo{width:100%;position:relative;height:210px;}.mobileImageContainer_m1kf5qpo img:not(:last-of-type){top:120px;display:block;margin-left:auto;margin-right:auto;width:100%;width:100%;height:100%;object-fit:cover;}@media (min-width:426px){.mobileImageContainer_m1kf5qpo{height:300px;}}@media (min-width:600px){.mobileImageContainer_m1kf5qpo{height:400px;}}
.image_idb34vj{-webkit-order:1;-ms-flex-order:1;order:1;position:relative;width:100%;min-height:100%;}@media (min-width:960px){.image_idb34vj{background-repeat:no-repeat;background-size:cover;background-position:bottom;-webkit-order:2;-ms-flex-order:2;order:2;min-width:100% !important;height:100% !important;min-height:100% !important;}}
.imagesContainer_im83eow{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-order:1;-ms-flex-order:1;order:1;min-height:100%;}@media (min-width:960px){.imagesContainer_im83eow{-webkit-order:2;-ms-flex-order:2;order:2;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex:1;-ms-flex:1;flex:1;}}
.textImage_tsbyrg8{position:absolute;bottom:5%;right:10px;width:auto;max-height:115px;}@media (min-width:600px){.textImage_tsbyrg8{max-height:150px;right:30px;}}@media (min-width:960px){.textImage_tsbyrg8{max-height:70%;}}
.animatedButtonIcon_at89llv .MuiButton-label img{margin-left:11px;position:relative;top:1px;-webkit-animation:bounce-icon-animatedButtonIcon_at89llv .4s infinite alternate;animation:bounce-icon-animatedButtonIcon_at89llv .4s infinite alternate;}@-webkit-keyframes bounce-icon-animatedButtonIcon_at89llv{from{-webkit-transform:translateX(0px);-ms-transform:translateX(0px);transform:translateX(0px);}to{-webkit-transform:translateX(-5px);-ms-transform:translateX(-5px);transform:translateX(-5px);}}@keyframes bounce-icon-animatedButtonIcon_at89llv{from{-webkit-transform:translateX(0px);-ms-transform:translateX(0px);transform:translateX(0px);}to{-webkit-transform:translateX(-5px);-ms-transform:translateX(-5px);transform:translateX(-5px);}}
.flagTitle_fl72n8x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;gap:3px;}.flagTitle_fl72n8x svg{padding-bottom:3px;}
.flagsContainer_ffvjrcl{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.flag_fnr37g9 .MuiTypography-root{background:#F7DDBB;border-radius:20px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding:8px 16px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;font-weight:600;margin:0 auto;}.flag_fnr37g9 .MuiTypography-root span{display:inline-block;}.flag_fnr37g9 .MuiTooltip-popper{margin-right:20%;}@media (min-width:600px){.flag_fnr37g9 .MuiTooltip-popper{margin-right:32px;}}.flag_fnr37g9 .MuiTooltip-touch{padding:4px 8px;}
.flagDefault_f1wjmqxu .MuiTypography-root{background:#FFFFFF;font-weight:700;}.flagDefault_f1wjmqxu .MuiTypography-root p{font-weight:700;}
.flagWarning_f11bcq4w .MuiTypography-root{background:#FF3D3D;color:#FFFFFF;}
.flagYellow_f1y6pie5 .MuiTypography-root{background:#FCD900;color:#2E0F4F;}
.customTooltip_c1q5w5qd{background:white !important;box-shadow:0px 3px 40px #0000001F;border-radius:20px !important;top:-15px;}.customTooltip_c1q5w5qd .MuiTypography-root{color:#524658 !important;background:white !important;font-weight:600;font-size:0.9375rem;padding:16px 24px;}.customTooltip_c1q5w5qd p{max-width:80%;}.customTooltip_c1q5w5qd a{display:inline-block;margin-top:10px;font-size:0.875rem;color:#524658;font-weight:400;}@media (min-width:960px){.customTooltip_c1q5w5qd{top:0;}}
.customArrow_cgttkyv{color:white !important;}
.tooltipCloseIcon_tsjewbj{position:absolute;top:12px;right:12px;color:#2E0F4F;}
.bundlesWrapper_b1ib47qm{margin-top:32px;}
.discountDetails_d1h5xki4.MuiTypography-root{color:#524658;font-size:15px;font-weight:normal;}
.bundleDetails_b18u0b9h{padding-top:8px;text-align:center;}@media (min-width:960px){.bundleDetails_b18u0b9h{text-align:left;}}
.alignTextLeft_ahbm63 .MuiTypography-root{text-align:left;}
.tag_t1vmrve7{min-height:-webkit-max-content;min-height:-moz-max-content;min-height:max-content;display:block;margin:1.5rem auto;max-width:87vw;width:100%;padding:5px 6px 7px;text-align:center;}@media (min-width:960px){.tag_t1vmrve7{max-width:420px;}}@media (min-width:1280px){.tag_t1vmrve7{max-width:100%;}}.tag_t1vmrve7 p{margin:0;}.tag_t1vmrve7 [class*='MuiTypography-']{color:white;font-weight:500;}
