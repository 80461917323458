.section_sqs7apn{padding-top:16px;}
.title_tyqftua{color:#2E0F4F;text-align:center;}.title_tyqftua.MuiTypography-root{font-size:1.875rem;}.title_tyqftua.MuiTypography-root{margin-bottom:40px;}@media (min-width:960px){.title_tyqftua.MuiTypography-root{margin-bottom:48px;font-size:2.188rem;}}
.bgPrimary_b12w0m6z{background-color:#2E0F4F;}
.bgSecondary_b1lh1bdb{background-color:#17AB33;}
.mapLegend_m6aldpz{padding:16px 0px;background:#F8F8F8;}.mapLegend_m6aldpz .MuiTypography-body2{font-weight:400;}@media (min-width:600px){.mapLegend_m6aldpz{padding:24px 0px 16px;}}
.mapWrapper_mpan0b8{min-height:580px;position:relative;}
.mapLegendWrapper_m14fbt7n{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.mapLegendWrapper_m14fbt7n.MuiGrid-item.rightSide{padding-left:80px;}@media (max-width:959.95px){.mapLegendWrapper_m14fbt7n.MuiGrid-item{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;padding:0px 16px;}}
.mapLegendItemAvailable_m1ru6vbm{background:#2E0F4F;}
.mapLegendItemUnavailable_m1t71dz0{background:#17AB33;}
.mapLegendItem_mv15zk5{width:20px;height:20px;margin:8px;border-radius:10px;display:block;}@media (min-width:600px){.mapLegendItem_mv15zk5{margin:16px;}}
.zoomOutButton_z90rt1i{cursor:pointer;position:absolute;z-index:100;top:8px;left:8px;}@media (min-width:600px){.zoomOutButton_z90rt1i{top:30px;left:30px;}}
@media (min-width:960px){.infoCard_ixbni3e{box-shadow:0px 3px 40px rgba(0,0,0,0.12);position:absolute;top:151px;left:110px;background:white;max-width:320px;border-radius:20px;}}
.infoCardWrapper_i1d9odxz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin:32px 18px;}@media (min-width:960px){.infoCardWrapper_i1d9odxz{margin:36px 32px 38px;}}
.infoCardSubtitle_ikdi1kv.MuiTypography-root{font-weight:400;color:#524658;margin-bottom:0;margin-top:16px;}@media (min-width:960px){.infoCardSubtitle_ikdi1kv.MuiTypography-root{margin-bottom:32px;}}
.infoCardButton_i1x0zf5i.MuiButton-root{display:none;}@media (min-width:960px){.infoCardButton_i1x0zf5i.MuiButton-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}
