.hidenProducts_hnh883w{display:none;}
.container_c1p3fo57{color:#2E0F4F;}.container_c1p3fo57 .MuiSvgIcon-colorSecondary{cursor:pointer;}.container_c1p3fo57 #payment-error{margin-top:40px;}
.detailContainer_dnxh9ty.MuiGrid-root{background-color:#F8F8F8;padding:24px;border-radius:20px;margin:8px 0;}.detailContainer_dnxh9ty.MuiGrid-root .MuiTypography-body2{font-weight:400;}@media (min-width:960px){.detailContainer_dnxh9ty.MuiGrid-root{-webkit-flex-basis:49%;-ms-flex-preferred-size:49%;flex-basis:49%;margin:16px 0;}}
.infoBanner_iz1r95j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:white;background-color:#848FA5;border-radius:20px;padding:16px;box-shadow:0px 3px 6px #00000054;}.infoBanner_iz1r95j .MuiSvgIcon-root{height:30px;width:30px;margin-right:8px;}
.infoBannerPrimary_i1gn1wqg{background-color:#2E0F4F;}
.productsContainer_p12zfa8v.MuiGrid-container{margin:8px 0;padding:24px;background-color:#F8F8F8;border-radius:20px;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2){border-bottom:1px solid #EBEBEB;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div .MuiSvgIcon-root{margin-right:5px;position:relative;top:5px;}@media (min-width:960px){.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2){display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;text-align:right;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div .MuiSvgIcon-root{margin-left:8px;}}@media (min-width:1280px){.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2) .MuiBox-root > div{-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:nth-child(2) .MuiSvgIcon-root{top:2px;}}.productsContainer_p12zfa8v .MuiTypography-body2{font-weight:400;}.productsContainer_p12zfa8v .MuiTypography-colorSecondary{color:#17AB33;}.productsContainer_p12zfa8v .MuiTypography-colorSecondary span:nth-child(2){font-size:1.125rem;}@media (min-width:960px){.productsContainer_p12zfa8v.MuiGrid-container{margin:16px 0;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:first-child{border-bottom:1px solid #EBEBEB;border-radius:0% !important;}.productsContainer_p12zfa8v .MuiGrid-grid-md-6:last-child{text-align:right;padding-top:24px !important;}}
.otsContainer_o13mwxzh{color:#524658;}.otsContainer_o13mwxzh h4{color:#2E0F4F;}.otsContainer_o13mwxzh.MuiGrid-container{margin:8px 0;padding:24px;background-color:#F8F8F8;border-radius:20px;}.otsContainer_o13mwxzh .MuiTypography-body1{font-weight:400;}.otsContainer_o13mwxzh .MuiTypography-body2{font-weight:400;}.otsContainer_o13mwxzh a{margin-left:4px;font-weight:700;color:#17AB33;white-space:nowrap;}.otsContainer_o13mwxzh a:hover{cursor:pointer;}.otsContainer_o13mwxzh strong,.otsContainer_o13mwxzh b{color:#524658;font-weight:600;}.otsContainer_o13mwxzh .MuiTooltip-popper{border-radius:8px;box-shadow:0px 3px 40px 0px rgba(0,0,0,0.16);top:-4px !important;width:284.96px;}@media (max-width:959.95px){.otsContainer_o13mwxzh .MuiTooltip-popper{top:-76px !important;left:50% !important;-webkit-transform:translateX(-50%) !important;-ms-transform:translateX(-50%) !important;transform:translateX(-50%) !important;}}.otsContainer_o13mwxzh .MuiTooltip-tooltip{background-color:white;margin:0;padding:24px;line-height:20.96px;font-size:1rem;color:#524658;font-weight:600;}.otsContainer_o13mwxzh .MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before{border-color:white transparent transparent transparent;}@media (max-width:959.95px){.otsContainer_o13mwxzh .MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before{display:none;}}.otsContainer_o13mwxzh #accountId-label{padding-right:24px;}.otsContainer_o13mwxzh .MuiCircularProgress-root{width:86.96px;height:86.96px;}
.toolTipWrapper_t1w0uh22{position:relative;z-index:2;}.toolTipWrapper_t1w0uh22 a{cursor:pointer;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;right:0;}@media (max-width:459.95px){.toolTipWrapper_t1w0uh22 a{top:8px;}}
.toolTipModal_t1ipi0bx b{font-weight:600 !important;}@media (max-width:599.95px){.toolTipModal_t1ipi0bx button{width:100%;}}
.progressScreen_pxkng7u .MuiCircularProgress-root{display:block;margin:0 auto;width:86.96px;height:86.96px;}
.mainIcon_m10pcvgd{width:50px !important;height:50px !important;}
.loading_lwm2j1e{margin-left:24px;vertical-align:middle;}
.errorLabel_eqt7vu1 .MuiInput-root.MuiInput-underline:before,.errorLabel_eqt7vu1 .MuiInput-root.MuiInput-underline:after,.errorLabel_eqt7vu1 .MuiInput-underline:hover:not(.Mui-disabled):before{border-bottom:1px solid #FF3D3D;}
.checkbox_c6vpyhy{position:relative;}.checkbox_c6vpyhy .MuiFormControlLabel-root{position:relative;margin-top:16px;}.checkbox_c6vpyhy .MuiCheckbox-root{position:absolute;top:-10.96px;}.checkbox_c6vpyhy .MuiFormControlLabel-label{color:#524658;padding-left:40px;font-size:0.875rem;}
.errorCheckbox_epodpsn span{color:#FF3D3D;}
.otsConfirmationError_ofewe1s{padding:24px;border-radius:20px;border:1px solid #FF3D3D;}.otsConfirmationError_ofewe1s svg{display:block;width:36px;height:36px;margin-bottom:8px;}
.monthlyCost_mwbhlns{color:#17AB33;}
.discount_d1gl7wde{color:#17AB33;}.discount_d1gl7wde.MuiTypography-root{font-size:15px;font-weight:normal;}
.vulnerableMention_v7p3ymh{padding-top:32px;}.vulnerableMention_v7p3ymh .MuiTypography-root{font-size:0.875rempx;font-weight:normal;}
.wier_wszbobq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}.wier_wszbobq svg{font-size:1.8rem;margin-right:2px !important;}@media (max-width:959.95px){.wier_wszbobq svg{left:-2px;}}
.summaryPrice_sisdrur{color:#524658;padding-top:16px;}
