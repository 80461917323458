.contractForm_c5t394d{width:100%;}.contractForm_c5t394d form{position:relative;margin:0 auto;background-color:white;border-radius:20px;}@media (min-width:960px){.contractForm_c5t394d form{margin-right:32px;}}.contractForm_c5t394d form .MuiFormLabel-root{font-size:20.96px;font-weight:700;}.contractForm_c5t394d form .MuiTextField-root{margin-top:16px;}.contractForm_c5t394d label + .MuiInput-formControl{margin-top:8px;}.contractForm_c5t394d input:-webkit-autofill,.contractForm_c5t394d input:-webkit-autofill:focus{-webkit-transition:background-color 600000s 0s,color 600000s 0s;transition:background-color 600000s 0s,color 600000s 0s;}.contractForm_c5t394d input[data-autocompleted]{background-color:transparent !important;}
.title_txxgzuz{max-width:100%;font-size:2.188rem !important;}
@media (min-width:960px){.iconsWithText_i1roraw9{padding-right:64px;}}@media (max-width:599.95px){.iconsWithText_i1roraw9 .iconsWithTextWrapper{padding:0;}.iconsWithText_i1roraw9 .iconsWithTextWrapper .iconTextWrapper{margin-left:16px;}}@media (max-width:599.95px){.iconsWithText_i1roraw9 section{margin-top:0;}}@media (min-width:960px){.iconsWithText_i1roraw9 h3{text-align:left;padding:0px 16px;font-size:2.813rem !important;}}@media (min-width:960px){.iconsWithText_i1roraw9 .MuiGrid-grid-sm-6{-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;text-align:left;}}@media (max-width:599.95px){.iconsWithText_i1roraw9 .MuiGrid-container{padding-top:32px;}.iconsWithText_i1roraw9 .MuiGrid-container .MuiGrid-item{padding-top:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;text-align:left;}}
.flagTag_f150unff{color:white;position:absolute;z-index:100;top:-8px;right:-8px;background-color:#FF3D3D;border-radius:5px 5px 0px 5px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.flagTag_f150unff .MuiTypography-h6{text-align:center;-webkit-transition:-webkit-transform 1s;-webkit-transition:transform 1s;transition:transform 1s;-webkit-transform:rotateY(0);-ms-transform:rotateY(0);transform:rotateY(0);padding:11px 24px;line-height:1;}.flagTag_f150unff span{font-size:0.75rem;font-weight:400;}.flagTag_f150unff:after{content:'';height:0;width:0;border-top:8px solid #80043B;border-right:8px solid transparent;position:absolute;bottom:-8px;right:0;}
.consentClasses_cyibkpg{margin:24px 8px 0px 8px;}.consentClasses_cyibkpg p{color:#524658;font-size:0.75rem;}.consentClasses_cyibkpg .privacyWrapper{padding:8px 0px 24px;}@media (max-width:599.95px){.consentClasses_cyibkpg .privacyWrapper{padding:8px 0px;}}.consentClasses_cyibkpg .privacyWrapper p{font-size:0.75rem;color:#524658;}
.savedData_s14bfryz{background-color:#F8F8F8;border-radius:10px;}.savedData_s14bfryz.MuiFormControl-root{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-content:center;-ms-flex-line-pack:center;align-content:center;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}.savedData_s14bfryz.MuiTextField-root{padding:16px;width:auto;}.savedData_s14bfryz label + .MuiInput-formControl{margin:0;padding:0;}.savedData_s14bfryz .Mui-disabled{color:#2E0F4F !important;-webkit-text-fill-color:#2E0F4F;font-size:1.125rem;}.savedData_s14bfryz .MuiInput-underline.Mui-disabled:before{border-bottom:none;}.savedData_s14bfryz .MuiInputLabel-formControl{position:relative;margin-bottom:8px;}.savedData_s14bfryz .MuiInputBase-input{padding:0px 8px 0px 0px;margin-top:0;color:#524658 !important;}
.contractFormBackground_c17gn01r{position:relative;}
.submitClasses_s1m1ngr3{margin:8px 0px 16px;}
.gridNoMargin_gqsha77.MuiGrid-container{margin:0;}
.inputLabel_iagmmk3.MuiTypography-root{margin:24px 8px;font-size:1.125rem;font-weight:700;color:#2E0F4F;padding-top:32px;}@media (min-width:960px){.inputLabel_iagmmk3.MuiTypography-root{margin:16px 8px;}}
.selectContainer_s12x4ztn.MuiGrid-root{margin-bottom:16px;}@media (min-width:960px){.selectContainer_s12x4ztn.MuiGrid-root{margin-bottom:-8px;}}
.selectClass_syh6r6p.MuiInput-root{background:#F8F8F8;border-radius:50px;overflow:hidden;width:100%;}.selectClass_syh6r6p.MuiInput-root .MuiSelect-select.MuiSelect-select{padding-left:24px;padding-top:12px;padding-bottom:14px;font-size:0.9375rem;font-weight:400;}
.activeSelect_alz2yri.MuiInput-root{background:#F8F8F8;color:#2E0F4F;}.activeSelect_alz2yri.MuiInput-root .MuiSelect-select.MuiSelect-select{color:#2E0F4F;}
.icon_i1cubx7u{padding-right:8px;}.icon_i1cubx7u.MuiSelect-icon{color:#2E0F4F;}
.activeIcon_afijpsa.MuiSelect-icon{color:#2E0F4F;}
.option_oq911my{border:none;outline:none;}.option_oq911my.MuiListItem-root{padding-top:0px;padding-bottom:0px;font:normal normal normal 15px/21px Poppins;}@media (min-width:960px){.option_oq911my.MuiListItem-root{padding-top:8px;padding-bottom:8px;}}.option_oq911my.MuiMenuItem-root:hover,.option_oq911my.MuiListItem-root.Mui-selected,.option_oq911my.MuiListItem-root.Mui-selected:hover{background:#EBEBEB;color:#2E0F4F;font-weight:500;}
.loading_l20pcp5{position:absolute;right:36.24px;}
.errorInput_e6nph14{border:1px solid red !important;}
.errorLabel_e1yel1k5{color:red !important;}
