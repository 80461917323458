@media (min-width:960px){.wrapper_w1wjjma1{overflow-x:auto;}}
.root_r1sfmc6f{margin:0 auto;padding-left:32px;padding-right:32px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}@media (max-width:959.95px){.root_r1sfmc6f{padding-left:5vw;padding-right:5vw;}}.root_r1sfmc6f img{height:40px;object-fit:contain;width:100%;}@media (max-width:599.95px){.root_r1sfmc6f img{height:32px;width:80%;}}.root_r1sfmc6f .MuiTableCell-body{color:#524658;background-color:#FFFFFF !important;}.root_r1sfmc6f::-webkit-scrollbar{color:white;padding-bottom:0px;}.root_r1sfmc6f tr:nth-of-type(odd) th:first-child,.root_r1sfmc6f .MuiTableRow-root:not(:last-child,:first-child){background-color:#FFFFFF !important;}.root_r1sfmc6f .MuiTableRow-root{margin:0px;}@media (min-width:960px){.root_r1sfmc6f .MuiTableRow-root th{text-align:center;}}@media (max-width:599.95px){.root_r1sfmc6f .MuiTableRow-root th .MuiTypography-root p,.root_r1sfmc6f .MuiTableRow-root td{font-size:0.75rem;}}.root_r1sfmc6f .MuiTableRow-root:nth-of-type(even) thead,.root_r1sfmc6f .MuiTableRow-root:nth-of-type(even) .MuiTableCell-root{background-color:#F8F8F8 !important;}.root_r1sfmc6f .MuiTableCell-root{overflow:hidden;padding:8px 16px !important;box-sizing:border-box;text-align:center;border:none;border-width:0;font-weight:400;}@media (min-width:1280px){.root_r1sfmc6f .MuiTableCell-root{padding:0 8px;}}.root_r1sfmc6f .MuiTableCell-root p{line-height:1.2;margin:0;}.root_r1sfmc6f .MuiTableCell-root:first-child p{text-align:left;}.root_r1sfmc6f .MuiTableCell-root:not(:last-child){border-right:solid 1px rgba(82,70,88,.16);}
.table_tlcos3w{border-radius:10px;border:1px solid #C8C8C8;margin:0 auto;}.table_tlcos3w.MuiTable-root{border-collapse:separate;overflow:hidden;width:auto;}@media (max-width:959.95px){.table_tlcos3w .MuiTypography-body1,.table_tlcos3w .MuiTypography-body2,.table_tlcos3w .MuiTypography-body1 p,.table_tlcos3w .MuiTypography-body2 p{font-size:0.75rem;}}
.tableCell_tlri4aa{width:180px;min-width:180px;}.tableCell_tlri4aa:first-child{width:200px;min-width:200px;}.tableCell_tlri4aa .MuiTypography-root{font-weight:400;color:#524658;}@media (max-width:959.95px){.tableCell_tlri4aa,.tableCell_tlri4aa:first-child{min-width:30vw;width:30vw;}}
.tableCellCompany_t13x0wl5 >.MuiBox-root{height:40px;}
.title_t1hq6zd9.MuiTypography-h3{text-align:center;padding-top:32px;padding-bottom:24px;color:#2E0F4F;}@media (min-width:960px){.title_t1hq6zd9.MuiTypography-h3{padding-top:0;padding-bottom:40px;font-size:2.188rem;}}.title_t1hq6zd9.MuiTypography-subtitle2{padding-top:16px;padding-bottom:32px;font-weight:400;margin-left:64px;color:#2E0F4F;}@media (min-width:960px){.title_t1hq6zd9.MuiTypography-subtitle2{width:90%;text-align:right;}}
.emptyHeaderCell_eadshnt{height:48px;}
.logo_l5t3bgn{max-width:90%;}@media (min-width:960px){.logo_l5t3bgn{max-width:100%;}}
.doneIncon_d1ebfth4 svg{color:#17AB33;}
.closeIcon_c1ug3ao1 svg{color:#FF3D3D;}
.primaryColourCell_p19znyps .MuiTypography-root{color:#2E0F4F;font-weight:700;}
.priceDiferenceColourCell_psadhyl .MuiTypography-root{color:#FF3D3D;font-weight:600;}
.secondaryColourCell_s19i1uob .MuiTypography-root{color:#17AB33;font-weight:600;}
