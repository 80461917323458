.h2_h1x6k8kx,.h2_h1x6k8kx.MuiTypography-root,.h2_h1x6k8kx.MuiSvgIcon-root{font-size:2.188rem;}
.book_b1vwbtx2,.book_b1vwbtx2.MuiTypography-root{font-weight:400 !important;}
.medium_m1g1tv8p,.medium_m1g1tv8p.MuiTypography-root{font-weight:500;}
.semibold_s1jusxqx,.semibold_s1jusxqx.MuiTypography-root{font-weight:600;}
.bold_b4td6mt,.bold_b4td6mt.MuiTypography-root{font-weight:700;}
.tight_tcz2fye,.tight_tcz2fye.MuiTypography-root{line-height:1.2;}
.md_m1ep31nn,.md_m1ep31nn.MuiTypography-root{margin-bottom:16px;}
.lg_l1kyqegl,.lg_l1kyqegl.MuiTypography-root{margin-bottom:24px;}
.lg_l1qeansq,.lg_l1qeansq.MuiTypography-root{margin-top:24px;}
.breakWord_b16ccntx{overflow-wrap:break-word;}
.primary_p12on4bd,.primary_p12on4bd.MuiTypography-root{color:#17AB33;font-weight:700;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;white-space:nowrap;}
.primary_p1buel1w,.primary_p1buel1w.MuiTypography-root{color:#2E0F4F;}
.none_nv84ayw,.none_nv84ayw.MuiSkeleton-text{-webkit-transform:none;-ms-transform:none;transform:none;}
.center_cnvcqbo{-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);}
.fakeField_f1lcfpzy .MuiInputBase-input{padding-top:8px;padding-bottom:0;}.fakeField_f1lcfpzy .MuiInputBase-root{padding:0;}.fakeField_f1lcfpzy .MuiFormLabel-root.Mui-disabled,.fakeField_f1lcfpzy .MuiInputBase-root.Mui-disabled{color:#2E0F4F;}.fakeField_f1lcfpzy .MuiInput-underline.Mui-disabled:before{border:none;}
