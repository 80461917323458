.radio_r553j4y.MuiFormControlLabel-root{box-sizing:border-box;padding-top:16px;height:100%;margin:auto;width:100%;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;color:#2E0F4F;}.radio_r553j4y .MuiRadio-root,.radio_r553j4y .MuiRadio-colorSecondary.Mui-checked{color:#2E0F4F;}.radio_r553j4y .MuiTypography-body2{font-weight:400;}
.radioGroup_r17brcer{padding-bottom:24px;}@media (max-width:959.95px){.radioGroup_r17brcer{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}
.inputsContainer_i1j4wk3y{gap:24px;}@media (max-width:1279.95px){.inputsContainer_i1j4wk3y{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}
.inputTitle_i1redtgy .MuiInput-formControl{margin-top:24px;max-width:352px;}@media (max-width:1279.95px){.inputTitle_i1redtgy .MuiInput-formControl{max-width:100%;width:100%;}}
.selectLabel_s8toa3p{margin-bottom:16px;font-size:1.125rem !important;-webkit-transform:translate(0,1.5px) scale(0.95) !important;-ms-transform:translate(0,1.5px) scale(0.95) !important;transform:translate(0,1.5px) scale(0.95) !important;z-index:2;}
.select_sf8bfgr{width:100%;}.select_sf8bfgr .MuiNativeSelect-select{background-color:#F8F8F8;border-radius:50px;overflow:hidden;width:100%;padding-left:24px;padding-top:12px;padding-bottom:14px;font-size:0.9375rem;font-weight:400;font-size:18px;}.select_sf8bfgr .MuiNativeSelect-icon{right:16px;color:#2E0F4F;}.select_sf8bfgr.Mui-focused .MuiNativeSelect-select{border-radius:50px;}
.formSuccess_fsfic66{padding:40px;height:400px;}.formSuccess_fsfic66 .MuiTypography-body1{font-weight:400;}
@media (min-width:960px){.prevProvContainer_p1ogudj3.MuiGrid-root{padding-right:16px;}}
.confirmButtonStyle_c1r1lih5.MuiGrid-root.MuiGrid-item{padding-top:32px;}.confirmButtonStyle_c1r1lih5 button{padding:0 64px!important;}
@media (max-width:1279.95px){.noXPaddingOnMobile_nabgx4j.MuiGrid-root.MuiGrid-item{padding-left:0;padding-right:0;}}
.firstInputTopPadding_fxegfrk.MuiGrid-root.MuiGrid-item{padding-top:16px;}
.secondInputTopPadding_ss5vwu2.MuiGrid-root.MuiGrid-item{padding-top:16px;}
