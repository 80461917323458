.closeIcon_c11xz9eg.MuiSvgIcon-root{color:white;position:absolute;right:0;padding:10px;font-size:20px;cursor:pointer;}
.dialog_d1fdp9ud .MuiDialog-paper{margin:0;}@media (max-width:959.95px){.dialog_d1fdp9ud .MuiDialog-paper{width:100%;}}.dialog_d1fdp9ud .MuiDialog-paperWidthMd{max-width:1312px;}.dialog_d1fdp9ud .MuiDialog-paperScrollPaper{max-height:100%;}
.dialogSuccess_d1tz3g8g .MuiDialog-paperScrollPaper{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (min-width:960px){.dialogSuccess_d1tz3g8g .MuiDialog-paperScrollPaper{height:70%;}}
.imageContainer_is98pmt{-webkit-order:0;-ms-flex-order:0;order:0;}@media (min-width:960px){.imageContainer_is98pmt{-webkit-order:1;-ms-flex-order:1;order:1;}}
.imageClass_i1ir39hn{width:100%;height:100%;object-fit:cover;}
.title_titl4k7.MuiTypography-root{text-align:center;}@media (min-width:960px){.title_titl4k7.MuiTypography-root{text-align:left;font-size:2.188rem;padding-bottom:24px;}}
.contentContainer_c153r705{padding:24px;}@media (min-width:960px){.contentContainer_c153r705{padding:48px;}}.contentContainer_c153r705 [class*='fieldContainer']{margin-top:16px;}
.option_omk48dx{border:none;outline:none;}.option_omk48dx.MuiListItem-root{padding-top:0px;padding-bottom:0px;font:normal normal normal 15px/21px Poppins;}@media (min-width:960px){.option_omk48dx.MuiListItem-root{padding-top:8px;padding-bottom:8px;}}.option_omk48dx.MuiMenuItem-root:hover,.option_omk48dx.MuiListItem-root.Mui-selected,.option_omk48dx.MuiListItem-root.Mui-selected:hover{color:white;background-color:#2E0F4F;}
.errorLabel_e1kas69t{color:#FF3D3D !important;}
.errorInput_e1dyokhh{border:1px solid #FF3D3D !important;}
.selectClass_s1h574dk.MuiInput-root{background-color:#F8F8F8;border-radius:50px;overflow:hidden;width:100%;}.selectClass_s1h574dk.MuiInput-root .MuiSelect-select.MuiSelect-select{padding-left:24px;padding-top:12px;padding-bottom:14px;font-size:0.9375rem;font-weight:400;}
.selectContainer_sgmlc4q.MuiGrid-root{margin-bottom:16px;}@media (min-width:960px){.selectContainer_sgmlc4q.MuiGrid-root{margin-bottom:-8px;}}
.activeSelect_a1k8b06y.MuiInput-root{background:#F8F8F8;color:#2E0F4F;}.activeSelect_a1k8b06y.MuiInput-root .MuiSelect-select.MuiSelect-select{color:#2E0F4F;}
.icon_irbo7th{padding-right:8px;}.icon_irbo7th.MuiSelect-icon{color:#2E0F4F;}
.activeIcon_a1nbtewu.MuiSelect-icon{color:#2E0F4F;}
.successMessage_s1wc0gf1.MuiTypography-root{text-align:center;}
@media (min-width:960px){.buttonWrapper_bkrktra.MuiGrid-justify-xs-center{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}}
.buttonClass_b1avvqt3.MuiButton-root{padding:0 81px !important;}
.loading_l101uvvr{position:absolute;right:36.24px;}
.inputLabel_i1674yah.MuiTypography-root{margin:24px 8px;font-size:1.125rem;font-weight:700;color:#2E0F4F;}
