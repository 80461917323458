.main_mprs2b8{position:fixed;background-color:#2E0F4F;color:white;opacity:0;top:185px;width:212px;max-height:140px;right:10px;z-index:1150;box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);border:1px solid white;border-radius:20px;padding-left:32px;padding-right:32px;padding-bottom:8px;padding-top:8px;line-height:1.4;-webkit-animation-name:opacityOn-main_mprs2b8;animation-name:opacityOn-main_mprs2b8;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-delay:1.5s;animation-delay:1.5s;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-iteration-count:1;animation-iteration-count:1;}@media (max-width:1279.95px){.main_mprs2b8{right:-12px;}}@-webkit-keyframes opacityOn-main_mprs2b8{0%{opacity:0;}100%{opacity:1;}}@keyframes opacityOn-main_mprs2b8{0%{opacity:0;}100%{opacity:1;}}
.arrowIcon_a1828x03{position:absolute;right:3px;top:0;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.arrowIcon_a1828x03 svg{font-size:14px;color:#17AB33;}
.buttonNoBg_b5981iy{background:none;border:none;padding:0;cursor:pointer;}.buttonNoBg_b5981iy:hover,.buttonNoBg_b5981iy:active,.buttonNoBg_b5981iy:focus{border:0;outline:0;}
.buttonClose_bsi081j{position:absolute;cursor:pointer;left:-15px;top:-15px;background:white;width:32px;height:32px;border-radius:15px;border-width:0px;color:#2E0F4F;}.buttonClose_bsi081j svg{height:15px;width:15px;color:#2E0F4F;padding-top:5px;}
.boxTop_bvboam6{color:#17AB33;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}@media (min-width:600px){.boxTop_bvboam6{padding-bottom:8px;}}
.boxMiddle_b17qfjaz{color:white;text-align:left;display:none;}.boxMiddle_b17qfjaz p{font-size:0.75rem;}@media (min-width:600px){.boxMiddle_b17qfjaz{display:block;}}
.boxBottom_b34bp5x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;color:white;padding-top:8px;min-width:180px;}.boxBottom_b34bp5x p{font-size:0.875rem;}
.separator_s3amy5i{display:inline-block;background:white;width:1px;margin-top:4px;height:12px;}
.finishText_fdt8bxf{padding-left:8px;}
.arrow_a17h0q5h{position:absolute;right:0;height:100%;}
