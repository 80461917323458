.container_c1i1ojvr{text-align:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.container_c1i1ojvr .MuiGrid-spacing-xs-4 > .MuiGrid-item{width:100%;}
.boxContainer_bt5bw1g{width:100%;max-width:1280px;margin:auto;}
.newVersionContainer_nmizbxj{margin:0 auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:2px solid #17AB33;border-radius:8px;}.newVersionContainer_nmizbxj a{-webkit-text-decoration:none;text-decoration:none;}
.newVersionText_n1dhbgt3{text-align:left;}
.styledText_s14b3hll{color:#524658;font-family:"Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";font-size:1.125rem;line-height:1.5;margin:0,auto;}.styledText_s14b3hll p{margin:auto;}@media (min-width:960px){.styledText_s14b3hll{max-width:580px;}}
.text_t1qq1x1u{padding:0 16px;max-width:580px;}.text_t1qq1x1u .MuiTypography-body1{line-height:1.5;margin:0 auto;font-weight:400;color:#524658;}@media (min-width:960px){.text_t1qq1x1u .MuiTypography-body1{max-width:580px;}}
@media (min-width:960px){.widerText_wzrbdpg{max-width:100%;}.widerText_wzrbdpg .MuiTypography-body1{max-width:100%;}}
.animatedButtonIcon_awvw61h .MuiButton-label img{margin-left:11px;position:relative;top:1px;-webkit-animation:bounce-icon-animatedButtonIcon_awvw61h .4s infinite alternate;animation:bounce-icon-animatedButtonIcon_awvw61h .4s infinite alternate;}@-webkit-keyframes bounce-icon-animatedButtonIcon_awvw61h{from{-webkit-transform:translateX(0px);-ms-transform:translateX(0px);transform:translateX(0px);}to{-webkit-transform:translateX(-5px);-ms-transform:translateX(-5px);transform:translateX(-5px);}}@keyframes bounce-icon-animatedButtonIcon_awvw61h{from{-webkit-transform:translateX(0px);-ms-transform:translateX(0px);transform:translateX(0px);}to{-webkit-transform:translateX(-5px);-ms-transform:translateX(-5px);transform:translateX(-5px);}}
.buttons_b15k7psx{width:100%;}.buttons_b15k7psx .MuiButtonBase-root{margin:8px 0px;}@media (min-width:600px){.buttons_b15k7psx .MuiButtonBase-root{margin:0px 8px;}}
.media_m1z0a2d5{max-width:1024px;margin:0;}
.mediaIcon_mzflqiu{margin-right:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (min-width:960px){.mediaIcon_mzflqiu{padding:9px;}}
.mediaImage_m1ohph5w{margin-bottom:32px;}.mediaImage_m1ohph5w img{width:auto;max-width:100%;}
.mediaVideo_m12s6gj4{margin-bottom:32px;}
