@media (min-width:960px){.bodyRight_b1vqtlg0{padding:0;padding-right:80px;margin:0 auto;}}
@media (min-width:960px){.bodyLeft_b14dtz8{padding:0;padding-left:80px;margin:0 auto;}}
.containerGen_cioi0xy .MuiCard-root{overflow:visible;}
.white_wmiuxz{color:#524658;overflow:visible !important;padding:40px 0px;background-color:white;}@media (min-width:960px){.white_wmiuxz{padding:64px 0px;}}.white_wmiuxz .MuiPaper-root{background:transparent;}
.gradient_g9q4ixz{color:#524658;overflow:visible !important;padding:40px 0px;background-image:linear-gradient(180deg,white 0%,#F8F8F8 100%);}@media (min-width:960px){.gradient_g9q4ixz{padding:64px 0px;}}.gradient_g9q4ixz .MuiPaper-root{background:transparent;}
.mediaWrapper_m3z93ky{position:relative;overflow:hidden;width:100%;}
.content_cjgqcik{font-weight:400;}.content_cjgqcik h2{font-size:1.875rem;line-height:1.15;color:#2E0F4F;}@media (min-width:960px){.content_cjgqcik h2{font-size:2.188rem;}}.content_cjgqcik p,.content_cjgqcik li{font-weight:400;color:#524658;}.content_cjgqcik li::marker{color:#2E0F4F;}.content_cjgqcik ul{list-style-image:url('/Icon_material-check.svg');padding-inline-start:16px;}@media (min-width:960px){.content_cjgqcik h2,.content_cjgqcik h3{margin:0px 0px 32px 0px;}}
@media (min-width:960px){.reverseContent_rsvd1c5{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}}
@media (max-width:959.95px){.mediaLeftAndDown_mk12ngk{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}}
.mediaRightAndUp_m1fj2rdw{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media (min-width:960px){.mediaRightAndUp_m1fj2rdw{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}}
.mediaRightAndDown_mpvokcz{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}@media (min-width:960px){.mediaRightAndDown_mpvokcz{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}}
.minWidth_m1sghjre{min-width:100%;}@media (min-width:960px){.minWidth_m1sghjre{min-width:auto;}}
.buttonsContainer_b1xo61by{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;overflow-x:scroll;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;padding:5px 0;}@media (min-width:960px){.buttonsContainer_b1xo61by{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;overflow-x:inherit;}}
.buttonsWrapper_bp4eedu{padding:0 8px 0 5px;}@media (min-width:960px){.buttonsWrapper_bp4eedu{padding:0px 16px;}}
.cancellationButton_cf1cafx{background-color:#fff !important;box-shadow:0px 0px 6px #00000029 !important;color:#2E0F4F !important;padding:0px 16px;width:165px;height:57px;-webkit-transition:-webkit-transform 1s;-webkit-transition:transform 1s;transition:transform 1s;}.cancellationButton_cf1cafx:hover{-webkit-transform:scale(1.025,1.025);-ms-transform:scale(1.025,1.025);transform:scale(1.025,1.025);}@media (min-width:960px){.cancellationButton_cf1cafx:hover{-webkit-transform:scale(1.1,1.1);-ms-transform:scale(1.1,1.1);transform:scale(1.1,1.1);}}
.image_i15io1pt{width:20px;height:20px;margin-left:8px;}
.anchorButton_a92b8fs.MuiButtonBase-root{-webkit-text-decoration:underline;text-decoration:underline;margin-top:8px;font-size:1.125rem;width:100%;}.anchorButton_a92b8fs.MuiButton-root:hover{background-color:transparent;-webkit-text-decoration:underline;text-decoration:underline;}@media (min-width:600px){.anchorButton_a92b8fs.MuiButtonBase-root{margin-top:0;margin-left:24px;width:auto;}}
.primaryButton_px3v8w3 .MuiButtonBase-root{width:100%;}@media (min-width:600px){.primaryButton_px3v8w3 .MuiButtonBase-root{width:auto;}}
.ctaRow_c1h9riqo .MuiGrid-root{margin:32px 0px;}
.smallText_s8q2ovo.MuiTypography-body1{font-size:0.75rem;color:#524658;font-weight:400;}
.iconsWithTextCustomIcons_i91fk7u{margin-bottom:32px;}
.iconItemContainer_i1iaf58a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}.iconItemContainer_i1iaf58a.MuiGrid-root{margin-top:16px;}.iconItemContainer_i1iaf58a img{margin-right:16px;}@media (min-width:600px){.iconItemContainer_i1iaf58a img{margin-right:24px;}}
.iconItemTitle_i1flnlub{padding-bottom:8px;}.iconItemTitle_i1flnlub.MuiTypography-root{color:#2E0F4F;font-weight:700;}
.iconItemText_i1nfgla1 p{margin-top:8px;margin:0;font-weight:400;line-height:27px;color:#524658;}.iconItemText_i1nfgla1 a{color:#17AB33;}
