.steps_s7hsvz0{position:relative;list-style:none;margin:3rem auto 2.5rem;padding:0;width:100%;max-width:602px;height:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;color:#a9a9a9;}@media (max-width:959.95px){.steps_s7hsvz0{margin:0;padding:0;margin-top:20px;max-width:90%;}}.steps_s7hsvz0:after{content:'';display:block;height:7px;width:92%;background-color:#e4e4e4;position:absolute;border-radius:8px;left:22px;top:-18px;}@media (max-width:959.95px){.steps_s7hsvz0:after{top:15px;height:2px;}}.steps_s7hsvz0:before{content:'';display:block;height:7px;width:92%;background-color:#2E0F4F;position:absolute;border-radius:8px;left:22px;top:-18px;z-index:2;-webkit-transition:width 2s;transition:width 2s;}@media (max-width:959.95px){.steps_s7hsvz0:before{top:15px;height:2px;}}.steps_s7hsvz0 li{width:auto;display:inline-block;height:20px;font-size:14px;}@media (max-width:959.95px){.steps_s7hsvz0 li{width:30px;height:30px;line-height:30px;display:inline-block;border:1px solid #C8C8C8;border-radius:50%;font-weight:600;text-align:center;z-index:3;background-color:white;font-weight:600;}}.steps_s7hsvz0 li a{-webkit-text-decoration:none;text-decoration:none;color:#2E0F4F;}@media (max-width:959.95px){.steps_s7hsvz0 li a{width:30px;height:30px;line-height:30px;display:inline-block;border:1px solid #2E0F4F;border-radius:50%;text-align:center;background-color:white;font-weight:600;}}
.lessMargin_lnruvjl{margin-bottom:0px;}@media (max-width:959.95px){.lessMargin_lnruvjl{margin-top:0px;margin-bottom:-10px;}}
@media (max-width:959.95px){.link_l1lex41y{border:none !important;}}
.activeStep_a1p4180j{color:#2E0F4F;font-weight:bold;}@media (max-width:959.95px){.activeStep_a1p4180j{border:1px solid #2E0F4F !important;background-color:#2E0F4F !important;color:white;}}
.step1_se99irf:before{width:8%;}@media (max-width:959.95px){.step1_se99irf:before{width:0%;}}
.step2_sukxk00:before{width:21%;}@media (max-width:959.95px){.step2_sukxk00:before{width:15%;}}
.step3_s1n38bqk:before{width:36.5%;}
.step4_s7b0iag:before{width:55%;}
.step5_s121i46o:before{width:76%;}
.step6_s1xom5hb:before{width:92%;}
