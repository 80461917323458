.container_c5txait{background-color:#F8F8F8;padding:32px 0;}@media (min-width:600px){.container_c5txait{padding:64px 0;}}.container_c5txait .MuiTabs-indicator{height:4px;border-radius:2px;background:#2E0F4F;}.container_c5txait .MuiTabs-flexContainer{max-width:100vw;}.container_c5txait .MuiTabs-flexContainer .MuiTab-root{margin:0 16px;min-width:auto;max-width:100%;color:#524658;font-size:1.125rem;font-weight:400;}@media (min-width:600px){.container_c5txait .MuiTabs-flexContainer{max-width:75vw;}}@media (min-width:960px){.container_c5txait .MuiTabs-flexContainer{max-width:55vw;}}
.textWrapper_t1d868j5{max-width:580px;}
.subtitle_s78adkd.MuiTypography-root{margin-top:16px;font-weight:400;}
.galleryContainer_gtcb851.MuiGrid-root{width:90%;height:500px;}@media (min-width:600px){.galleryContainer_gtcb851.MuiGrid-root{height:250px;}}@media (min-width:960px){.galleryContainer_gtcb851.MuiGrid-root{height:400px;}}@media (min-width:1280px){.galleryContainer_gtcb851.MuiGrid-root{width:60%;height:500px;}}
.gallery_gm7blku.MuiGrid-root{margin:16px 0;max-height:90%;overflow-y:auto;}@media (min-width:960px){.gallery_gm7blku{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.gallery_gm7blku .MuiGrid-grid-md-true{-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}}
.buttonContainer_b6ne36e{margin:16px 0;background-color:white;border-radius:100px;box-shadow:0px 3px 40px #0000001F;}
.gridButton_g87kio4.MuiButton-root{border-radius:100px;width:150px;}@media (min-width:600px){.gridButton_g87kio4.MuiButton-root{width:172px;}}.gridButton_g87kio4.MuiButton-contained{background:none;color:#524658;}.gridButton_g87kio4.MuiButton-contained:hover{background:none;}.gridButton_g87kio4 p{font-size:0.9375rem;}.gridButton_g87kio4 p span{font-weight:400;}
.active_a1xp3d7m.MuiButtonBase-root,.active_a1xp3d7m.MuiButton-root{background-color:#2E0F4F;color:white;}.active_a1xp3d7m.MuiButtonBase-root:hover,.active_a1xp3d7m.MuiButton-root:hover{background-color:rgb(87,63,114);}
.image_i1kn8lwj{width:100%;object-fit:fill;border-radius:10px;}@media (min-width:960px){.image_i1kn8lwj{width:auto;max-height:100px;}}
.info_i119cown{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (min-width:600px){.info_i119cown{-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}}.info_i119cown .MuiTypography-root{font-weight:400;}.info_i119cown.MuiGrid-root{width:70%;}
.ctaButton_csr5ih5.MuiButton-root{margin:16px;width:90%;}@media (min-width:600px){.ctaButton_csr5ih5.MuiButton-root{width:auto;padding:8px 40px;}}
