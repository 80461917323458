.input_inq4ld7.MuiInputBase-root{color:#2E0F4F;-webkit-flex:1;-ms-flex:1;flex:1;padding:16px;font-weight:400 !important;}.input_inq4ld7 input::-webkit-input-placeholder{color:#EBEBEB;font-style:italic;}.input_inq4ld7 input::-moz-placeholder{color:#EBEBEB;font-style:italic;}.input_inq4ld7 input:-ms-input-placeholder{color:#EBEBEB;font-style:italic;}.input_inq4ld7 input::placeholder{color:#EBEBEB;font-style:italic;}
.searchWrapper_s13v1fhc{position:relative;}
.searchRoot_s12mayz2.MuiPaper-rounded{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:50px;border-radius:25px !important;box-shadow:0px 0px 6px #00000029;}.searchRoot_s12mayz2.MuiPaper-rounded svg{padding-right:10px;}
.container_cesc7xn .MuiPaper-rounded{border-radius:10px;}
.wrapper_wdfkuzh{border-radius:20px !important;}
.headingWrapper_hrefb3j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:32px 16px;background-color:#2E0F4F;border-top-left-radius:10px;border-top-right-radius:10px;}@media (min-width:960px){.headingWrapper_hrefb3j{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;padding:32px 64px;}}
.title_t1863jng{-webkit-flex:1;-ms-flex:1;flex:1;}
.subtitle_s14wvlj6{-webkit-flex:1;-ms-flex:1;flex:1;color:white;font-weight:400 !important;}
.heading_h9fx2yn.MuiTypography-root{font-size:3rempx;color:white;padding-bottom:0;}
.boxContainer_b15exl0w{padding-right:16px;padding-left:16px;}@media (min-width:960px){.boxContainer_b15exl0w{padding-right:64px;padding-left:64px;}}
.contentContainer_c9t6tup{-webkit-flex-wrap:nowrap !important;-ms-flex-wrap:nowrap !important;flex-wrap:nowrap !important;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media (min-width:960px){.contentContainer_c9t6tup{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}
.contentItem_cp2n0pm{-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}@media (min-width:960px){.contentItem_cp2n0pm{padding-right:64px;}}
.label_ltpr0sj{-webkit-transform:translate(0,1.5px) scale(0.95);-ms-transform:translate(0,1.5px) scale(0.95);transform:translate(0,1.5px) scale(0.95);-webkit-transform-origin:top left;-ms-transform-origin:top left;transform-origin:top left;margin-bottom:16px;line-height:1.2 !important;}
.addressResult_a9abkl3.MuiList-root{max-height:456px;overflow:scroll;background-color:#fafafa;margin:0;margin-top:2px;z-index:1100;position:absolute;max-width:100%;width:410px;border-radius:4px;}
.addressResultItem_ajdxcqs.MuiListItem-root{padding-left:8px;cursor:pointer;}.addressResultItem_ajdxcqs.MuiListItem-root:hover{background-color:#eeeeee;}.addressResultItem_ajdxcqs.MuiListItem-root .MuiTypography-root{color:#212121;}
.textContainer_t1cqk1r2{padding:0 16px;}@media (min-width:960px){.textContainer_t1cqk1r2{padding:0 64px;}}
