.wrapper_w1rtnd4f{color:#2E0F4F;padding-bottom:56px;}
.wrapperInner_w1xhprhx{max-width:1024px;}
.curveContainer_cf36phh{padding:56px 0px;}@media (min-width:960px){.curveContainer_cf36phh .MuiTypography-root{font-size:2.188rem;}}.curveContainer_cf36phh .MuiTypography-h1{margin-bottom:32px;font-size:2.188rem;}.curveContainer_cf36phh .MuiTypography-h2{font-size:0.9375rem;padding-bottom:16px;}@media (min-width:600px){.curveContainer_cf36phh .MuiTypography-h2{font-size:1.875rem;padding-bottom:32px;}}.curveContainer_cf36phh .MuiTypography-h3{font-size:0.9375rem;}@media (min-width:600px){.curveContainer_cf36phh .MuiTypography-h3{font-size:1.125rem;padding-bottom:32px;}}
.subTitle_s1j3devb{text-align:center;position:relative;top:-40px;}.subTitle_s1j3devb div{background-color:white;border-radius:50px;box-shadow:0px 3px 40px #00000014;padding:16px 40px;display:inline-block;}.subTitle_s1j3devb .MuiTypography-root{position:relative;bottom:3px;}
.orderInfo_o1dutptd{padding:0 19.2px;max-width:1024px;margin:16px auto 0;}@media (min-width:960px){.orderInfo_o1dutptd >:nth-child(even){margin-left:24px;margin-right:24px;}.orderInfo_o1dutptd >:nth-child(4){padding-top:40px;}.orderInfo_o1dutptd >[class*="productInfoGrid"]{max-width:250px;}}
.productInfoGrid_pu0be3q img{max-width:40px;margin-bottom:16px;}.productInfoGrid_pu0be3q .MuiTypography-h5{font-weight:500;}.productInfoGrid_pu0be3q .MuiTypography-body1{font-weight:400;color:#524658;}.productInfoGrid_pu0be3q .MuiTypography-body1 a{color:#17AB33;}.productInfoGrid_pu0be3q .MuiTypography-body1 > div > p{margin:8px 0px 0px;}@media (max-width:959.95px){.productInfoGrid_pu0be3q{margin-left:16px;}}@media (min-width:960px){.productInfoGrid_pu0be3q{text-align:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.productInfoGrid_pu0be3q .MuiTypography-root{padding-left:0;}.productInfoGrid_pu0be3q img{max-width:64px;}}
.OTSwrapper_o2ya31n{color:#2E0F4F;padding-bottom:56px;}
.OTSwrapperInner_o791lpd{max-width:1024px;}
.OTStitle_ootok3j{font-weight:600;font-size:1.563rem;color:#2E0F4F;}
.OTScontainer_o1ixvj0i{color:#524658;}.OTScontainer_o1ixvj0i.MuiGrid-container{margin:8px 0;padding:24px;background-color:#F8F8F8;border-radius:20px;}.OTScontainer_o1ixvj0i .MuiTypography-body2{font-weight:400;}.OTScontainer_o1ixvj0i a{margin-left:4px;font-weight:700;color:#17AB33;white-space:nowrap;}.OTScontainer_o1ixvj0i a:hover{cursor:pointer;}.OTScontainer_o1ixvj0i strong,.OTScontainer_o1ixvj0i b{color:#524658;font-weight:600;}
