@media (min-width:960px){.componentTitle_c43bvcg.MuiTypography-h3{font-size:2.188rem;}}
.bodyText_b1dhbt1q.MuiTypography-body1{text-align:center;color:#524658;font-weight:400;max-width:580px;}
.cardOuter_c1qym638{background:none;border:none;min-width:100%;padding:0;cursor:pointer;position:relative;-webkit-transition:top .2s ease-in-out;transition:top .2s ease-in-out;top:0;color:#2E0F4F;}@media (min-width:960px){.cardOuter_c1qym638:hover{position:relative;top:-8px;}}
.overlayWrapper_o1a6mtrt:after{content:"";position:absolute;z-index:-10;top:0;left:0;height:100%;width:100%;background-color:#2E0F4F;opacity:.6;border-radius:20px;}
.cardFlipped_cqv5cwg{-webkit-transform:rotateY(180deg) !important;-ms-transform:rotateY(180deg) !important;transform:rotateY(180deg) !important;-webkit-transition:-webkit-transform 1s;-webkit-transition:transform 1s;transition:transform 1s;-webkit-transform-style:preserve-3d;-ms-transform-style:preserve-3d;transform-style:preserve-3d;}
.inner_i1numxmi{min-height:450px;box-shadow:0px 3px 40px #0000001F;border-radius:20px;min-width:100%;position:relative;text-align:center;-webkit-transform:rotateY(0);-ms-transform:rotateY(0);transform:rotateY(0);-webkit-transition:-webkit-transform 1s;-webkit-transition:transform 1s;transition:transform 1s;-webkit-transform-style:preserve-3d;-ms-transform-style:preserve-3d;transform-style:preserve-3d;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.cardSide_c2bzlii{min-height:450px;position:relative;-webkit-backface-visibility:hidden;backface-visibility:hidden;overflow:hidden;border-radius:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-transition:-webkit-transform .2s ease-in-out !important;-webkit-transition:transform .2s ease-in-out !important;transition:transform .2s ease-in-out !important;padding:0px 24px;}
.cardShortSide_cwd9bv7{min-height:330px;max-height:330px;border-radius:20px 20px 0 0;}.cardShortSide_cwd9bv7::after{border-radius:20px 20px 0 0;}
.front_fkqkz2r{-webkit-transform:rotateY(0);-ms-transform:rotateY(0);transform:rotateY(0);overflow:visible;background-size:cover;background-repeat:no-repeat;background-position:center;}.front_fkqkz2r .MuiTypography-root{color:white;}@media (min-width:960px){.front_fkqkz2r .MuiTypography-h3{font-size:2.188rem;}}
.back_b1vu2vnh{background-color:white;position:absolute;-webkit-transform:rotateY(180deg);-ms-transform:rotateY(180deg);transform:rotateY(180deg);min-height:100%;overflow:visible;text-align:left;}.back_b1vu2vnh .MuiTypography-body2{margin-bottom:16px;}.back_b1vu2vnh .MuiTypography-caption{color:#524658;font-size:0.9375rem;}
.features_f1dfz3e7{font-weight:400;}.features_f1dfz3e7 .MuiTypography-root{color:#524658;text-align:left;font-weight:400;}.features_f1dfz3e7 p{color:#524658;font-weight:400;}.features_f1dfz3e7 ul{list-style:none;margin-top:8px;margin-left:16px;padding:0;color:#524658;font-weight:400;}.features_f1dfz3e7 ul li{padding:6px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.features_f1dfz3e7 ul li:before{margin-top:2px;content:url('/icon-check-primary.svg');position:relative;left:-11px;}
.flipCardInner_f1fjrmmo{width:100%;}
.readMoreOverlay_r1n3bgq2.MuiTypography-root{color:white;}
.frontButton_f1tqixol{color:white;position:absolute;bottom:32px;right:32px;background:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border:none;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;}.frontButton_f1tqixol img{margin-left:8px;}
.frontReadButton_flhf1pu{color:white;position:absolute;bottom:16px;right:0;left:0;margin:0px 24px;background:none;border:none;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;text-align:center;}.frontReadButton_flhf1pu .MuiTypography-body1.MuiTypography-colorPrimary{margin-top:8px;-webkit-text-decoration:underline;text-decoration:underline;}.frontReadButton_flhf1pu .MuiTypography-root img{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;margin-left:8px;}
.sliderContainer_s1q6lxp5 .slick-slide > div{margin:0 4px;width:75vw;}.sliderContainer_s1q6lxp5 .slick-slide{padding-bottom:32px;}.sliderContainer_s1q6lxp5 .slick-list{overflow:visible !important;-webkit-transition:all ease-in-out .5s;transition:all ease-in-out .5s;}@media (min-width:960px){.sliderContainer_s1q6lxp5 .slick-list .slick-slide > div{width:auto;}}
.backButtonContainer_bafyl8e{margin-bottom:32px;}.backButtonContainer_bafyl8e button{background:none;border:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;cursor:pointer;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-text-decoration:none;text-decoration:none;color:#2E0F4F;}.backButtonContainer_bafyl8e button svg{color:#2E0F4F;height:12px;position:relative;top:2px;}.backButtonContainer_bafyl8e img{cursor:pointer;margin-left:auto;}
