.mainBox_mppt6lt{border-top:1px solid #C8C8C8;}
.gridContainer_ge9gf4i{padding:1rem;}
.menuTitle_m13e5n0t{padding-bottom:0 !important;}
.social_s10l3uns img{height:30px;}
.address_a1fmv42n{text-align:right;}.address_a1fmv42n .MuiTypography-gutterBottom{margin-bottom:16px;}
.logo_lhjfmcu{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;text-align:right;padding-top:16px !important;}
.backToTop_blbzso1{margin-right:8px;}@media (min-width:1280px){.backToTop_blbzso1{margin-right:32px;}}
.container_c1jaw6ie{border-top:1px solid #2E0F4F;padding:24px 16px 8px;}.container_c1jaw6ie .MuiCollapse-container{width:100%;padding-left:24px;}.container_c1jaw6ie .MuiCollapse-container .MuiTypography-root{padding:8px 0;}.container_c1jaw6ie .MuiCollapse-container .MuiTypography-root:hover{-webkit-text-decoration:underline;text-decoration:underline;}.container_c1jaw6ie .MuiListItem-root{padding:0 !important;padding-bottom:16px !important;}
.address_a84pvq4{text-align:left;}.address_a84pvq4 .MuiTypography-gutterBottom{margin-bottom:16px;}
.logo_lkn1tab{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;text-align:left;padding:8px 0 24px;max-width:75vw !important;}
.menuItem_mh2k9ir{position:relative;cursor:pointer;}.menuItem_mh2k9ir .MuiSvgIcon-root{-webkit-transition:all 0.1s ease-in-out;transition:all 0.1s ease-in-out;position:absolute;right:12px;bottom:17px;height:20px;}.menuItem_mh2k9ir.MuiGrid-container{border-bottom:1px solid #EBEBEB;}
.openMenuItem_o1yc6smu.MuiGrid-container{border-bottom:1px solid white;}.openMenuItem_o1yc6smu.MuiGrid-container .MuiSvgIcon-root{-webkit-transition:all 0.1s ease-in-out;transition:all 0.1s ease-in-out;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);height:21px;}
.social_szs8xhw.MuiGrid-item{padding-bottom:32px !important;}.social_szs8xhw .MuiTypography-root{padding-bottom:8px;}.social_szs8xhw a{padding-right:24px;display:inline-block;}
