.bannerContainerWhite_b8kg1y0{background-color:white;color:#2E0F4F;}@media (min-width:960px){.bannerContainerWhite_b8kg1y0::after{background-color:white;}}@media (max-width:599.95px){.bannerContainerWhite_b8kg1y0::before{background-color:white;}}
.bannerContainerPurple_brven4b{background-color:#2E0F4F;color:white;}@media (min-width:960px){.bannerContainerPurple_brven4b::after{background-color:#2E0F4F;}}@media (max-width:599.95px){.bannerContainerPurple_brven4b::before{background-color:#2E0F4F;}}
.bannerContainer_b15flul1{height:inherit;position:relative;z-index:1;}
@media (min-width:960px){.bannerTitle_b1j18g2r.MuiTypography-h3{font-size:2.188rem;}}
.infoContainer_ipu13nw{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:8px 32px 32px;background-repeat:no-repeat;background-size:cover;background-position:right top 70%;}.infoContainer_ipu13nw p{margin:0;}@media (min-width:960px){.infoContainer_ipu13nw{padding:56px 48px;}}
.bannerBodyPurple_bf48k57.MuiTypography-body1{color:white !important;}@media (min-width:960px){.bannerBodyPurple_bf48k57.MuiTypography-body1{font-weight:300;}}
.bannerBody_b13eg5sa{padding-top:16px;font-weight:400;color:#524658 !important;}.bannerBody_b13eg5sa.MuiTypography-body1{font-weight:400;font-size:1.125rem;}
.buttonContainer_b18746nf{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100% !important;}@media (min-width:960px){.buttonContainer_b18746nf .MuiGrid-grid-md-6{margin-right:24px;}}.buttonContainer_b18746nf .MuiButton-root{margin-top:24px;width:100%;}
.imageContainerPurple_iwpv9cj.MuiGrid-item{background-color:#2E0F4F;}
.imageContainer_i1vo9qx1{min-height:350px;height:100%;position:relative;background-repeat:no-repeat;background-size:cover;background-position:50%;}@media (max-width:599.95px){.imageContainer_i1vo9qx1{min-height:184px;}}
.imageCurveWrapper_irrvud2{position:relative;}
.textPurple_tvyli7i.MuiTypography-body1{color:white;}
.text_toz3jop.MuiTypography-body1{font-weight:400;-webkit-text-decoration:underline;text-decoration:underline;width:100%;text-align:center;margin-top:16px;}@media (min-width:960px){.text_toz3jop.MuiTypography-body1{text-align:left;margin-top:24px;}}
.desktopCurve_d1wbqqar{height:auto !important;width:auto !important;position:absolute;top:0;left:0;max-height:100%;}
.mobileCurve_msb8k2t{width:100% !important;height:auto !important;bottom:-1px;left:0;position:absolute;}
