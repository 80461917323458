.slider_s388pyx .slick-track{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;}.slider_s388pyx .slick-track .slick-slide{float:none;height:auto;}.slider_s388pyx .slick-track .slick-slide > div{height:100%;}.slider_s388pyx .slick-list{overflow:visible !important;-webkit-transition:all ease-in-out .5s;transition:all ease-in-out .5s;}.slider_s388pyx:hover .slick-arrow{visibility:visible;}.slider_s388pyx .slick-arrow{visibility:hidden;position:absolute;z-index:10;background:#52465880;height:160px;width:42px;top:calc(50% - 15px);margin:0 24px;height:160px;}.slider_s388pyx .slick-arrow:before{color:transparent;border:solid white;opacity:1;border-width:0 3px 3px 0;display:inline-block;}.slider_s388pyx .slick-prev:before{position:relative;left:2px;-webkit-transform:rotate(135deg);-ms-transform:rotate(135deg);transform:rotate(135deg);}.slider_s388pyx .slick-next:before{position:relative;right:2px;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);}
@media (min-width:1280px){.sliderOffCentre_srksmth .slick-list{left:-200px;}}
.bigScreenCards_b15u4zy7{display:grid;}.bigScreenCards_b15u4zy7 .slick-list{left:0;}.bigScreenCards_b15u4zy7 .slick-track{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100vw !important;}
.modalScreen_m1bd92tb{pointer-events:none;}
.modalBox_m1a43luj{pointer-events:auto;}
.selectedCard_s9np971{border:3px solid #2E0F4F;}
.cardFlipped_c1d9c5nq{-webkit-transform:rotateY(180deg) !important;-ms-transform:rotateY(180deg) !important;transform:rotateY(180deg) !important;-webkit-transition:-webkit-transform 1s;-webkit-transition:transform 1s;transition:transform 1s;-webkit-transform-style:preserve-3d;-ms-transform-style:preserve-3d;transform-style:preserve-3d;}
.inner_i1kfxavy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;min-width:100%;position:relative;-webkit-transform:rotateY(0);-ms-transform:rotateY(0);transform:rotateY(0);-webkit-transition:-webkit-transform 1s;-webkit-transition:transform 1s;transition:transform 1s;-webkit-transform-style:preserve-3d;-ms-transform-style:preserve-3d;transform-style:preserve-3d;height:100%;}@media (max-width:959.95px){.inner_i1kfxavy{margin-bottom:15px;}}
.front_flxo38t{-webkit-transform:rotateY(0);-ms-transform:rotateY(0);transform:rotateY(0);overflow:visible;}
.back_b1erkinu{position:absolute;-webkit-transform:rotateY(180deg);-ms-transform:rotateY(180deg);transform:rotateY(180deg);min-height:100%;overflow:visible;}
.oldPrice_oy8xdvs{-webkit-text-decoration:line-through;text-decoration:line-through;margin-right:8px !important;}
.decimals_dxgazq5{position:absolute;bottom:100%;-webkit-transform:translateY(4px);-ms-transform:translateY(4px);transform:translateY(4px);}
.fraction_f4y26iz{position:relative;}
.twistButton_t1gwj2k5{margin-top:12px;border:0;background:none;cursor:pointer;}.twistButton_t1gwj2k5:focus:not(.focus-visible){outline:none;}
.hide_hx5y01k{display:none;}
.selected_s11opbz8{background-color:#17AB33 !important;color:white;opacity:unset !important;}
.priceLabel_p193miit.MuiTypography-root{margin-top:2px;}
.huge_hxh97qt[class*=MuiTypography]{font-size:60px;}.huge_hxh97qt + .right [class*=fraction]{font-size:20px;}
.fontWeightNormal_fa31taq.MuiTypography-body1{font-weight:400;}
.iconWithText_i1mbhy43{text-align:center;}.iconWithText_i1mbhy43 p:first-child{margin-top:0;}
.spinner_sqnhh6s{position:relative;display:block;margin:0 auto;width:100%;max-width:1152px;height:590px;}.spinner_sqnhh6s div[class*='spinner']{position:absolute;top:40%;left:50%;-webkit-transform:translate(-50%,-40%);-ms-transform:translate(-50%,-40%);transform:translate(-50%,-40%);right:unset;width:120px;height:120px;}.spinner_sqnhh6s div[class*='spinner'] div{width:120px;height:120px;}.spinner_sqnhh6s div[class*='messageWrapper']{top:120px;}
.error_e1wk47gn{position:relative;display:block;margin:0 auto;width:100%;max-width:1152px;}
.root_r1chdqlq{position:relative;}.root_r1chdqlq .MuiListItemAvatar-root{min-width:0;}.root_r1chdqlq .MuiAvatar-colorDefault{background:none;}.root_r1chdqlq .MuiListItemText-root{margin:0;}.root_r1chdqlq .MuiListItem-root{padding:0;}.root_r1chdqlq .MuiAvatar-root{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}
