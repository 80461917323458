.popper_pervpbl{border-radius:20px;-webkit-transform:translateY(8px);-ms-transform:translateY(8px);transform:translateY(8px);}.popper_pervpbl .MuiAutocomplete-paper{background-color:#FFFFFF;border:1px solid #17AB33;border-top:1px solid #17AB33 !important;box-shadow:none;}.popper_pervpbl .MuiAutocomplete-paper ul li.MuiAutocomplete-option{padding:6px 24px;}.popper_pervpbl .MuiAutocomplete-paper:before{content:"";position:absolute;z-index:-1;top:50px;right:0;bottom:0;left:0;background-color:transparent !important;border-radius:inherit;}.popper_pervpbl .MuiAutocomplete-noOptions{color:#524658;font-weight:500;text-align:left;}
.popperPrimary_p3e88na{background-color:#fafafa;}.popperPrimary_p3e88na .MuiAutocomplete-paper{border:none;background-color:white;}
.autocomplete_a11snhmb{position:relative;z-index:1;background-color:#FFFFFF;border-radius:30px;max-width:100%;margin:auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.autocomplete_a11snhmb .MuiAutocomplete-clearIndicator{background-color:white;}@media (min-width:960px){.autocomplete_a11snhmb .MuiAutocomplete-clearIndicator{margin-right:0px;}}.autocomplete_a11snhmb .MuiInputBase-root{color:#524658;font-size:1rem;-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;border:0;z-index:1100;}.autocomplete_a11snhmb .MuiInputBase-root,.autocomplete_a11snhmb .MuiInputBase-root.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]{padding:0px;}.autocomplete_a11snhmb .MuiInputBase-root input{box-sizing:border-box;min-height:46px;padding:8px 48px 8px 24px !important;margin-right:0px;border-radius:30px;}.autocomplete_a11snhmb .MuiOutlinedInput-notchedOutline{border:none;}
.form_f1f34akt{padding:20px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:700px;margin:auto;}.form_f1f34akt .MuiTypography-h3{color:white;font-size:2.125rempx;}@media (min-width:960px){.form_f1f34akt .MuiTypography-h3{font-size:3rempx;margin-right:24px;}}.form_f1f34akt .MuiExpansionPanel-root{box-shadow:none;background:transparent;}.form_f1f34akt .MuiExpansionPanelDetails-root{padding:0;}
.formPrimaryBg_fmfc96b{background-color:#2E0F4F;}
.fontContainerPrimary_f1bsmwn7{background-color:white;border:1px solid white;}
.mobileButton_m2xml0h.MuiFab-root,.mobileButton_m2xml0h.MuiFab-root:hover{height:40px;width:40px;color:white;background-color:#17AB33;margin-right:3px;z-index:2;position:absolute;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);right:0%;}.mobileButton_m2xml0h.MuiFab-root .MuiSvgIcon-root,.mobileButton_m2xml0h.MuiFab-root:hover .MuiSvgIcon-root{font-size:1.3rem;margin-left:3px;}
.inputContainer_iyi2kgj{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;max-width:100%;position:relative;z-index:2;}
.item_i4rkl27 .MuiAutocomplete-option{color:#524658;font-size:1.25rem;padding:0;text-align:left;}.item_i4rkl27 .MuiAutocomplete-option[data-focus=true]{background-color:#FAFAFA;color:#2E0F4F;text-shadow:0.25px 0.1px 0px #524658,-0.25px -0.1px 0px #524658;}
.searchbar_s11ondbq{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}.searchbar_s11ondbq .MuiFormControl-root,.searchbar_s11ondbq .MuiFormControl-root input{height:100%;}.searchbar_s11ondbq .MuiFormControl-root .MuiInputBase-root{padding-bottom:0;padding-top:0;}
.errorMessage_el7uy6n{color:#FF3D3D;opacity:100%;}
